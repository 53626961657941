<template>
	<div
		class="mt-3 mx-0 body-2 font-weight-light d-flex flex-row justify-space-between align-start"
		@mouseover="display = true"
		@mouseleave="display = false"
	>
		<div>
			<h4 class="entity-title font-weight-normal subtitle-1">
				{{ Award.name }}
			</h4>
			<p class="second-line">
				{{ Award.givenBy }}
			</p>
			<p class="second-line">{{ Award.year }}</p>
		</div>
		<v-btn
			v-show="display"
			fab
			small
			color="white"
			dark
			elevation="0"
			right
			class="edit"
			absolute
			@click="showDialog = !showDialog"
		>
			<v-icon> mdi-pencil </v-icon>
		</v-btn>
		<AwardsForm
			v-if="showDialog"
			:isEdit="true"
			:initialData="Award"
			:dialog="showDialog"
			@closeModal="closeModal"
			@deleteAward="deleteAward"
			@addAward="addAward"
		></AwardsForm>
	</div>
</template>
<script>
import AwardsForm from './AwardsForm'
export default {
	components: { AwardsForm },
	props: {
		Award: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			display: false,
			showDialog: false,
		}
	},
	methods: {
		closeModal() {
			this.showDialog = !this.showDialog
		},
		deleteAward(awardToDelete) {
			this.$emit('deleteAward', awardToDelete)
		},
		addAward() {
			this.$emit('addAward')
		},
	},
}
</script>
<style scoped>
.edit {
	background-color: white;
	color: var(--blue);
}
.edit:hover {
	background-color: var(--blue-bg) !important;
}
</style>
