<template>
	<v-container v-if="$can('view', 'Page')" fluid>
		<v-card v-if="!isLoading" class="pa-8 pb-6">
			<FileUploader
				:initFiles="banner ? [banner] : []"
				@errorOccured="dropzoneError"
				:purpose="`about banner`"
				:maxFiles="1"
				@changed="fileUploaderChanged"
			/>
			<v-row no-gutters>
				<v-col cols="12">
					<p class="ma-0 label mt-3 mb-1">Content :</p>
					<div id="ckcontent">
						<Editor
							:initData="content"
							:purpose="`content`"
							@changed="editorChanged"
						></Editor>
					</div>
				</v-col>
				<v-col cols="12 mt-4">
					<p class="ma-0 label">Team:</p>
					<Search
						class="mt-2"
						:initialData="professionals"
						:url="`/${$store.state.app.projectId}/professionals/search`"
						@search-result="updateProfessionals($event)"
					></Search>
				</v-col>
				<v-col cols="12 mt-4">
					<p class="ma-0 label">Services:</p>
					<Search
						class="mt-3"
						:initialData="services"
						:url="`/${$store.state.app.projectId}/services/search`"
						@search-result="updateService($event)"
					></Search>
				</v-col>
				<v-col cols="12 mt-4">
					<ExperienceCard
						:experienceArray="experiences"
					></ExperienceCard>
				</v-col>
				<v-col cols="12 mt-2">
					<AwardsCard :awardArray="awards"></AwardsCard>
				</v-col>
			</v-row>
			<v-card-actions class="card-actions px-0">
				<!-- <div>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								color="#f44336"
								class="save-btn mr-4 pa-3"
								text
								@click="patchData()"
							>
								Save<span
									class="mdi mdi-check pl-2 icon"
								></span>
							</v-btn>
						</template>
						<span class="tooltip-text">Save</span>
					</v-tooltip>
				</div> -->
				<UnoSaveButton
					@click.native="putData()"
					@primary-button-click="dashboard()"
					primary-button-text="Move To Dashboard"
					secondary-button-text="Continue Editing"
					:alert="alert"
					:saving="isSaving"
				></UnoSaveButton>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import Search from '@/components/Search'
import ExperienceCard from './components/ExperienceCard'
import AwardsCard from './components/AwardsCard'
// ACTION BUTTONS
import FileUploader from '@/components/FileUploader'
import Editor from '@/components/CkEditor'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
export default {
	data() {
		return {
			experiences: [],
			awards: [],
			content: '',
			services: [],
			banner: null,
			bannerId: null,
			isLoading: false,
			professionals: [],
			isSaving: false,
			alert: {
				show: null,
				message: null,
				error: false,
			},
		}
	},
	created() {
		this.getInitialData()
	},
	components: {
		FileUploader,
		Editor,
		Search,
		ExperienceCard,
		AwardsCard,
		UnoSaveButton,
	},
	methods: {
		updateService(array) {
			this.services = array
		},
		updateProfessionals(professionalsArray) {
			this.professionals = professionalsArray
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.bannerId = data.id
				this.purpose = 'about banner'
				this.banner = {
					id: this.bannerId,
					publicURL: data.publicURL,
				}
			} else {
				this.bannerId = null
				this.banner = null
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
		editorChanged(body) {
			this.content = body
		},
		getInitialData() {
			this.isLoading = true
			const projectId = this.$store.state.app.projectId
			this.axios
				.get(`/${projectId}/pages/about-us`)
				.then((res) => {
					if (res.status === 404) {
						console.log(404)
					}
					const data = res.data.meta
					this.getdata(data)
					this.isLoading = false
				})
				.catch(() => {
					this.isLoading = false
				})
		},
		getdata(data) {
			this.experiences = data.experiences
			var awardData = data.awards
			for (var i = 0; i < awardData.length; i++) {
				if (awardData[i].banner)
					awardData[i].bannerId = awardData[i].banner.media
			}
			this.awards = awardData

			this.services = data.services
			this.content = data.content
			this.professionals = data.professionals.map((obj) => ({
				name: obj.fullName,
				id: obj.id,
			}))
			this.banner = data.banner
			this.bannerId = data.banner ? data.banner.media : null
		},
		async putData() {
			const projectId = this.$store.state.app.projectId
			let payload = {
				banner: this.bannerId,
				content: this.content,
				experiences: this.experiences,
				awards: this.awards.map((obj) => ({
					name: obj.name,
					givenBy: obj.givenBy,
					year: obj.year,
					banner:
						obj.banner && obj.banner.publicURL
							? obj.bannerId
							: null,
				})),
				services: this.services.map((obj) => {
					return obj['id'] ? obj['id'] : obj
				}),
				professionals: this.professionals.map((obj) => {
					return obj['id'] ? obj['id'] : obj
				}),
			}
			await this.axios({
				method: 'put',
				url: `/${projectId}/pages/about-us`,
				data: payload,
			})
				.then((response) => {
					if (response.status === 404) {
						console.log(404)
					}
					if (response.status == 200 && response.data.success) {
						this.alert.message = response.data.message
					}
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.isSaving = false
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
		dashboard() {
			this.$router.push({
				name: 'dashboard',
			})
		},
	},
}
</script>
<style scoped>
.save-btn {
	color: white !important;
	background-color: var(--blue) !important;
	padding: 0 16px !important;
}
.heading {
	color: green;
	font-weight: 300;
}
.success-modal .heading {
	color: #4daf51;
	font-weight: 400;
	font-size: 1.5rem;
}
.success-modal .icon {
	font-size: 1.7rem;
	color: #4daf51;
}
</style>
