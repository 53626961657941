<template>
	<v-dialog v-model="dilog" persistent max-width="800px">
		<v-card class="pa-6 mb-0">
			<div class="d-flex justify-space-between align-start">
				<h1 class="text-h6 mb-5">Edit Experience</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>

			<v-form ref="form" v-on:submit.prevent="submit">
				<v-row>
					<v-col cols="12" class="px-0 pb-0">
						<p>Role:</p>
						<v-text-field
							class="pa-0"
							placeholder="Surveillance Medical Office"
							v-model="role"
						></v-text-field>
					</v-col>

					<v-col cols="12" class="px-0 pb-0">
						<p>Organisation:</p>
						<v-text-field
							class="pa-0"
							placeholder=" NPSP, World Health Organization & Government of India"
							v-model="organisation"
						></v-text-field>
					</v-col>

					<v-col cols="6" class="ma-0 pa-0 pr-4">
						<p>Start Year :</p>
						<v-select
							class="pa-0"
							placeholder="Select Year"
							@focus="dropdown()"
							:items="yearsArray"
							v-model="startedYear"
						></v-select>
					</v-col>
					<v-col cols="6" class="py-0 pr-0">
						<p>End Year:</p>
						<v-select
							class="pa-0"
							placeholder="Select Year"
							@focus="dropdown()"
							:items="yearsArray"
							v-model="endedYear"
						></v-select>
					</v-col>
				</v-row>

				<v-card-actions class="pt-6 card-actions">
					<v-btn
						v-if="isEdit"
						color="rgba(0,0,0,.6)"
						outlined
						class="delete-btn pa-3"
						@click="deleteExperience(initialData)"
					>
						Delete
					</v-btn>

					<v-spacer></v-spacer>

					<v-btn
						color="#2b6dc2"
						depressed
						type="submit"
						class="save-btn pa-3"
					>
						Done
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		newExperience: {
			type: Object,
		},
		initialData: {
			type: Object,
		},
		isEdit: {
			type: Boolean,
		},
	},
	data() {
		return {
			dilog: true,
			yearsArray: [],
			startedYear: null,
			endedYear: null,
			role: '',
			organisation: '',
		}
	},
	created() {
		if (this.isEdit) {
			this.startedYear = this.initialData.startedYear
			this.endedYear = this.initialData.endedYear
			this.role = this.initialData.role
			this.organisation = this.initialData.organisation
			this.uuid = this.initialData.uuid
		}
	},
	methods: {
		resetValidation() {
			this.$emit('closeModal')
		},
		dropdown: function () {
			let currentYear = new Date().getFullYear()
			let yearArray = this.yearsArray
			let earliestYear = 1940
			while (currentYear >= earliestYear) {
				yearArray.push(currentYear)
				currentYear -= 1
			}
			this.yearsArray = yearArray
		},
		submit: function () {
			if (!this.isEdit) {
				this.newExperience.startedYear = this.startedYear
				this.newExperience.endedYear = this.endedYear
				this.newExperience.role = this.role
				this.newExperience.organisation = this.organisation
				this.$emit('addExperience')
			} else {
				this.initialData.startedYear = this.startedYear
				this.initialData.endedYear = this.endedYear
				this.initialData.role = this.role
				this.initialData.organisation = this.organisation
				this.$emit('closeModal')
			}
		},
		deleteExperience(experienceToDelete) {
			this.$emit('deleteExperience', experienceToDelete)
			this.$emit('closeModal')
		},
	},
}
</script>
