<template>
	<v-card class="pa-6" id="experience">
		<div class="d-flex flex-row justify-space-between align-end mb-5">
			<h1 class="text-h6">Experiences</h1>
			<v-btn
				color="var(--blue)"
				dark
				right
				rounded
				elevation="0"
				class="add-more"
				@click="closeModal"
			>
				<v-icon left>mdi-plus</v-icon> Add
			</v-btn>
		</div>

		<ExperienceForm
			v-if="showDialog"
			:dialog="showDialog"
			:newExperience="newExperience"
			@closeModal="closeModal"
			@addExperience="addExperience"
			v-bind:experienceArray="experienceArray"
		></ExperienceForm>

		<ListOfExperiences
			v-for="experience in experienceArray"
			:key="experience.uuid"
			:experience="experience"
			@deleteExperience="deleteExperience"
		>
		</ListOfExperiences>
	</v-card>
</template>
<script>
import ExperienceForm from './ExperienceForm'
import ListOfExperiences from './ListOfExperiences'
export default {
	components: {
		ExperienceForm,
		ListOfExperiences,
	},
	data() {
		return {
			showDialog: false,
			newExperience: {},
			display: false,
		}
	},
	props: {
		experienceArray: {
			type: Array,
		},
	},
	methods: {
		closeModal() {
			this.showDialog = !this.showDialog
		},
		addExperience() {
			this.showDialog = !this.showDialog
			var x = this.newExperience
			this.experienceArray.push(x)
			this.newExperience = {}
		},
		deleteExperience(experienceToDelete) {
			for (var i = 0; i < this.experienceArray.length; i++) {
				var data = this.experienceArray[i]
				if (
					data.role == experienceToDelete.role &&
					data.startedYear == experienceToDelete.startedYear &&
					data.endedYear == experienceToDelete.endedYear &&
					data.organisation == experienceToDelete.organisation
				) {
					this.experienceArray.splice(i, 1)
				}
			}
		},
	},
}
</script>

<style scoped>
.add-more {
	color: white;
	font-size: 13px !important;
}
.add-more i {
	font-size: 18px !important;
}
.edit {
	background-color: white;
	color: var(--blue);
}
.edit:hover {
	background-color: var(--blue-bg) !important;
}
</style>
