<template>
	<v-card class="pa-6" id="experience">
		<div class="d-flex flex-row justify-space-between align-end mb-5">
			<h1 class="text-h6">Awards</h1>
			<v-btn
				color="var(--blue)"
				dark
				right
				rounded
				elevation="0"
				class="add-more"
				@click="closeModal"
			>
				<v-icon left>mdi-plus</v-icon> Add
			</v-btn>
		</div>

		<AwardsForm
			v-if="showDialog"
			:dialog="showDialog"
			:newAward="newAward"
			@closeModal="closeModal"
			@addAward="addAward"
			v-bind:awardArray="awardArray"
		></AwardsForm>

		<ListOfAwards
			v-for="award in awardArray"
			:key="award.uuid"
			:Award="award"
			@deleteAward="deleteAward"
		>
		</ListOfAwards>
	</v-card>
</template>

<script>
import AwardsForm from './AwardsForm'
import ListOfAwards from './ListOfAwards'
export default {
	components: {
		AwardsForm,
		ListOfAwards,
	},
	data() {
		return {
			showDialog: false,
			newAward: {},
			display: false,
		}
	},
	props: {
		awardArray: {
			type: Array,
		},
	},
	methods: {
		closeModal() {
			this.showDialog = !this.showDialog
		},
		addAward() {
			this.showDialog = !this.showDialog
			var x = this.newAward
			this.awardArray.push(x)
			this.newAward = {}
			console.log(this.awardArray)
		},
		deleteAward(awardToDelete) {
			for (var i = 0; i < this.awardArray.length; i++) {
				var data = this.awardArray[i]
				if (
					data.name == awardToDelete.name &&
					data.year == awardToDelete.year &&
					data.givenBy == awardToDelete.givenBy
				) {
					this.awardArray.splice(i, 1)
				}
			}
		},
	},
}
</script>

<style scoped>
.add-more {
	color: white;
	font-size: 13px !important;
}
.add-more i {
	font-size: 18px !important;
}
.edit {
	background-color: white;
	color: var(--blue);
}
.edit:hover {
	background-color: var(--blue-bg) !important;
}
</style>
