<template>
	<div>
		<v-autocomplete
			v-model="selectedId"
			:loading="isLoading"
			:disabled="disable"
			:placeholder="placeholder"
			:items="serverResponse"
			type="text"
			item-text="name"
			item-value="id"
			cache-items
			hide-no-data
			:search-input.sync="keyword"
			@change="handleSelect()"
			:counter-value="selectLimit"
			dense
		></v-autocomplete>
		<v-chip
			v-for="item in selectedItems"
			:key="item.id"
			class="chip mr-2"
			close
			@click:close="remove(item.id)"
		>
			{{ item.name }}
		</v-chip>
	</div>
</template>
<script>
import _ from 'lodash'
export default {
	props: {
		initialData: {
			type: Array,
		},
		url: {
			type: String,
			required: true,
		},
		selectLimit: {
			type: Number,
			default: null,
		},
		placeholder: {
			type: String,
			default: 'Search and select from suggestions.',
		},
	},
	data() {
		return {
			isLoading: false,
			disable: false,
			selectedId: '',
			serverResponse: [],
			selectedItems: [],
			helper_text: null,
			keyword: '',
		}
	},
	computed: {
		serverResponseByIds() {
			const collection = this.serverResponse
			const obj = {}
			collection.forEach((item) => {
				obj[item.id] = item
			})
			return obj
		},
		selectedItemHash() {
			const items = this.selectedItems
			const hash = {}
			items.forEach((item) => {
				hash[item.id] = true
			})
			return hash
		},
	},
	created() {
		this.selectedItems = this.initialData
		this.debouncedDoSearch = _.debounce(this.doSearch, 500)
		this.debouncedEmitData = _.debounce(this.emitData, 150)
	},
	watch: {
		keyword: function (newKeyword, oldKeyword) {
			if (this.keyword !== null && newKeyword !== oldKeyword) {
				this.debouncedDoSearch()
			}
		},
		selectedItems() {
			this.debouncedEmitData()
		},
		initialData() {
			this.selectedItems = this.initialData
		},
	},
	methods: {
		doSearch: function () {
			if (this.keyword && this.keyword.length >= 3) {
				this.isLoading = true
				const vm = this
				this.axios({
					method: 'get',
					url: this.url,
					params: {
						keyword: this.keyword,
					},
				})
					.then((response) => {
						this.serverResponse = response.data.data
					})
					.catch(function (error) {
						console.log(error)
					})
					.finally(function () {
						vm.isLoading = false
					})
			}
		},
		handleSelect() {
			const selectedId = this.selectedId
			const selectedItemHash = this.selectedItemHash
			const serverResponseByIds = this.serverResponseByIds
			/* avoids selecting when the item is already selected */
			if (!(selectedId in selectedItemHash)) {
				const selectedObj = serverResponseByIds[selectedId]
				this.selectedItems = [...this.selectedItems, ...[selectedObj]]
			}
			this.resetInput()
		},
		emitData() {
			this.$emit('search-result', this.selectedItems)
		},
		remove: function (id) {
			let items = this.selectedItems
			let indexToSplice
			items.every(function (item, index) {
				if (item.id === id) {
					indexToSplice = index
					return false // to break the loop
				} else {
					return true
				}
			})
			if (indexToSplice !== undefined) {
				items.splice(indexToSplice, 1)
			}
			this.selectedItems = items
		},
		resetInput() {
			this.$nextTick(() => {
				this.selectedId = null
				this.keyword = ''
			})
		},
	},
}
</script>
<style scoped>
.helper-text {
	margin-bottom: 10px;
}
.chip {
	background-color: #f3f3f3 !important;
	border: 1px solid #ccc;
}
.chip > i {
	cursor: pointer;
	float: right;
	font-size: 16px;
	line-height: 32px;
	padding-left: 8px;
}
#results {
	color: var(--blue);
	font-weight: 500;
	font-size: 1.1rem;
}
</style>
