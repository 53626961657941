<template>
	<v-dialog v-model="dilog" persistent max-width="800px">
		<v-card class="pa-6 mb-0">
			<div class="d-flex justify-space-between align-start">
				<h1 class="text-h6 mb-5">Edit Award</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>

			<v-form ref="form" v-on:submit.prevent="submit">
				<v-row>
					<v-col cols="12" class="px-0 pb-0">
						<FileUploader
							:initFiles="banner ? [banner] : []"
							@errorOccured="dropzoneError"
							:purpose="`award banner`"
							:maxFiles="1"
							@changed="fileUploaderChanged"
						/>
					</v-col>

					<v-col cols="12" class="px-0 pb-0">
						<p>Achivement:</p>
						<v-text-field
							class="pa-0"
							placeholder="Surveillance Medical Office"
							v-model="name"
						></v-text-field>
					</v-col>

					<v-col cols="12" class="px-0 pb-0">
						<p>Given By:</p>
						<v-text-field
							class="pa-0"
							placeholder=" NPSP, World Health Organization & Government of India"
							v-model="givenBy"
						></v-text-field>
					</v-col>

					<!-- <v-col cols="6" class="ma-0 pa-0 pr-4">
						<p>Month :</p>
						<v-select
							class="pa-0"
							placeholder="Select Month"
							@focus="dropdownMonth()"
							:items="monthsArray"
							v-model="month"
						></v-select>
					</v-col> -->

					<v-col cols="12" class="pa-0">
						<p>Year:</p>
						<v-select
							class="pa-0"
							placeholder="Select Year"
							@focus="dropdown()"
							:items="yearsArray"
							v-model="year"
						></v-select>
					</v-col>
				</v-row>

				<v-card-actions class="pt-6 card-actions">
					<v-btn
						v-if="isEdit"
						color="rgba(0,0,0,.6)"
						outlined
						class="delete-btn pa-3"
						@click="deleteAward(initialData)"
					>
						Delete
					</v-btn>

					<v-spacer></v-spacer>

					<v-btn
						color="#2b6dc2"
						depressed
						type="submit"
						class="save-btn pa-3"
					>
						Done
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import FileUploader from '@/components/FileUploader'
export default {
	props: {
		newAward: {
			type: Object,
		},
		initialData: {
			type: Object,
		},
		isEdit: {
			type: Boolean,
		},
	},
	components: {
		FileUploader,
	},
	data() {
		return {
			dilog: true,
			yearsArray: [],
			monthsArray: [],
			month: null,
			year: null,
			name: '',
			givenBy: '',
			banner: null,
			bannerId: null,
		}
	},
	created() {
		if (this.isEdit) {
			// this.month = this.initialData.month
			this.bannerId = this.initialData.bannerId
			this.banner = this.initialData.banner
			this.year = this.initialData.year
			this.name = this.initialData.name
			this.givenBy = this.initialData.givenBy
			this.uuid = this.initialData.uuid
		}
	},
	methods: {
		resetValidation() {
			this.$emit('closeModal')
		},
		dropdown: function () {
			let currentYear = new Date().getFullYear()
			let yearArray = this.yearsArray
			let earliestYear = 1940
			while (currentYear >= earliestYear) {
				yearArray.push(currentYear)
				currentYear -= 1
			}
			this.yearsArray = yearArray
		},
		// dropdownMonth: function () {
		// 	let monthsArray = this.monthsArray
		// 	monthsArray.push('January')
		// 	monthsArray.push('February')
		// 	monthsArray.push('March')
		// 	monthsArray.push('April')
		// 	monthsArray.push('May')
		// 	monthsArray.push('June')
		// 	monthsArray.push('July')
		// 	monthsArray.push('August')
		// 	monthsArray.push('September')
		// 	monthsArray.push('Octuber')
		// 	monthsArray.push('November')
		// 	monthsArray.push('December')
		// 	this.monthsArray = monthsArray
		// },
		submit: function () {
			if (!this.isEdit) {
				// this.newAward.month = this.month
				this.newAward.year = this.year
				this.newAward.name = this.name
				this.newAward.givenBy = this.givenBy
				this.newAward.banner = this.banner
				this.newAward.bannerId = this.bannerId
				this.$emit('addAward')
			} else {
				// this.initialData.month = this.month
				this.initialData.banner = this.banner
				this.initialData.bannerId = this.bannerId
				this.initialData.year = this.year
				this.initialData.name = this.name
				this.initialData.givenBy = this.givenBy
				this.$emit('closeModal')
			}
		},
		deleteAward(awardToDelete) {
			this.$emit('deleteAward', awardToDelete)
			this.$emit('closeModal')
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.bannerId = data.id
				this.banner = {
					id: this.bannerId,
					publicURL: data.publicURL,
				}
			} else {
				this.bannerId = null
				this.banner = null
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
	},
}
</script>
